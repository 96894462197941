import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import {
  SupportRequestsResponse,
  SupportRequestSort,
  AppSupportRequestBrief,
  AppSupportRequestBriefSerialized,
} from '@playq/services-beetle';
import { Filter, Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { UseQueryOptionsExtended } from '/api/service-hooks';
import { QueryHelpers } from '/helpers/query';
import { requestsKeys } from '/component/Support/BeetlesLookup/constants';
import { TBettleId, TIssueOrRequestSort } from '/component/Support/BeetlesLookup/types';

import { useQueryBase } from './useCustomQuery';

export const useSupportRequestQuery = (
  env: Environment,
  iterator: OffsetLimit = QueryHelpers.getInitIterator(),
  sortBy: TIssueOrRequestSort[] = [],
  filterBy: {
    [key: string]: Filter;
  } = {},
  options?: UseQueryOptionsExtended<SupportRequestsResponse | undefined, GenericFailure | Error>
) => {
  return useQueryBase<
    SupportRequestsResponse,
    AppSupportRequestBrief,
    TBettleId,
    SupportRequestSort,
    AppSupportRequestBriefSerialized
  >(
    requestsKeys,
    env,
    services2.audienceSupportService.querySupportRequests.bind(services2.audienceSupportService),
    { iterator, sortBy: sortBy as SupportRequestSort[], filterBy },
    (request) => request?.id,
    (response) => response.requests ?? [],
    (response, newEntities) => {
      response.requests = newEntities;
    },
    (prevResponse) => new SupportRequestsResponse(prevResponse.serialize()),
    (response) => response.serialize().requests,
    (newArray, updatedIterator, updatedTotal) =>
      new SupportRequestsResponse({
        requests: newArray,
        iterator: updatedIterator,
        total: updatedTotal,
      }),
    options
  );
};
