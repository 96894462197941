import { Updater } from '@tanstack/react-query';

import { Note } from '@playq/services-beetle';

import { NoteSerializedProp } from '/component/Support/BeetlesLookup/types';

/**
 * @template T - current entity(issue or request) from  response type(issues or requests) .
 */
export const addNote =
  <T extends { notes: Note[] }>(
    cb: (oldData: T) => T,
    mutate: (updator: Updater<T | undefined, T | undefined>) => void
  ) =>
  (currentNote: NoteSerializedProp) => {
    return mutate((prevResponse) => {
      if (prevResponse === undefined) return;
      const newResponse = cb(prevResponse);
      const newNote = new Note({ ...currentNote, updatedAt: new Date().toISOString() });
      newResponse.notes = [newNote, ...newResponse.notes];
      return newResponse;
    });
  };
export const updateNote =
  <T extends { notes: Note[] }>(
    cb: (oldData: T) => T,
    mutate: (updator: Updater<T | undefined, T | undefined>) => void
  ) =>
  (currentNote: NoteSerializedProp) => {
    return mutate((prevResponse) => {
      if (prevResponse === undefined) return;
      const newResponse = cb(prevResponse);
      newResponse.notes = prevResponse.notes.map((note) => {
        if (note.id === currentNote.id) {
          const updatedNote = new Note(note.serialize());
          updatedNote.note = currentNote.note;
          return updatedNote;
        }
        return note;
      });
      return newResponse;
    });
  };

export const removeNote =
  <T extends { notes: Note[] }>(
    cb: (oldData: T) => T,
    mutate: (updator: Updater<T | undefined, T | undefined>) => void
  ) =>
  (noteId: string) => {
    return mutate((prevResponse) => {
      if (prevResponse === undefined) return;
      const newResponse = cb(prevResponse);
      newResponse.notes = prevResponse.notes.filter((note) => note.id !== noteId);
      return newResponse;
    });
  };
export const updateResolved =
  <T extends { resolved: boolean }>(
    cb: (oldData: T) => T,
    mutate: (updator: Updater<T | undefined, T | undefined>) => void
  ) =>
  (currentResolved: boolean) => {
    return mutate((prevResponse) => {
      if (prevResponse === undefined) return;
      const newResponse = cb(prevResponse);
      newResponse.resolved = currentResolved;
      return newResponse;
    });
  };
