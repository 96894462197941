import { MutationKey } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';

import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { TBettleId, SupportContentType } from '/component/Support/BeetlesLookup/types';

import { methods } from './serviceHandlerRequest';

export type DeletePayloadType = {
  id: TBettleId;
  env: Environment;
};

export const deleteIssueMutationKey: MutationKey = ['mutation', 'delete'];

export const useDelete = (
  method: SupportContentType,
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, DeletePayloadType>
) => {
  const service = methods(method);
  return useEitherMutation(({ id, env }) => service.deleteEntity(id, env), {
    onError: (err) => snackbarService.genericFailure(err.message),
    mutationKey: deleteIssueMutationKey,
    ...options,
  });
};
