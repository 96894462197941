import { useMemo } from 'react';
import { Updater, UseQueryOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { AppIssue, AppSupportRequest } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { useEitherQuery } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { audienceRetrieveQueryKeys } from '/component/Support/BeetlesLookup/constants';
import { SupportContentType, TBettleId } from '/component/Support/BeetlesLookup/types';

import { methods } from './serviceHandlerRequest';
import { addNote, removeNote, updateNote } from './notesCache';
export const useRetrieve = (
  id: TBettleId,
  type: SupportContentType,
  env: Environment = Environment.Live,
  options?: UseQueryOptions<AppIssue | AppSupportRequest | undefined, GenericFailure | Error>
) => {
  const keys = [...audienceRetrieveQueryKeys, id, env];
  const service = methods(type);
  const defaultEntity =
    type === SupportContentType.ISSUES || type === SupportContentType.ISSUES_ATTACHMENTS
      ? new AppIssue()
      : new AppSupportRequest();
  const res = useEitherQuery(keys, () => service.retrieve(id, env), {
    keepPreviousData: true,
    onError: (err) => snackbarService.genericFailure(err.message),
    ...options,
  });
  const createPrevDataClass = (oldData: AppIssue | AppSupportRequest): AppIssue | AppSupportRequest => {
    if (oldData instanceof AppIssue) {
      return new AppIssue(oldData.serialize());
    } else if (oldData instanceof AppSupportRequest) {
      return new AppSupportRequest(oldData.serialize());
    }
    throw new Error('Unsupported data type');
  };

  const createMutateUpdated = (mutate: (updator: Updater<AppIssue | undefined, AppIssue | undefined>) => void) => {
    return (status: boolean) => {
      mutate((prevResponse) => {
        if (!prevResponse) {
          return undefined;
        }
        const newResponse = new AppIssue(prevResponse.serialize());
        newResponse.resolved = status;
        return newResponse;
      });
    };
  };
  const mutateUpdated = createMutateUpdated(
    res.mutate as (updator: Updater<AppIssue | undefined, AppIssue | undefined>) => void
  );

  const cacheOptimization = useMemo(() => {
    return {
      addNote: addNote(createPrevDataClass, res.mutate),
      removeNote: removeNote(createPrevDataClass, res.mutate),
      updateNote: updateNote(createPrevDataClass, res.mutate),
      updateResolved: (status: boolean) => mutateUpdated(status),
    };
  }, [mutateUpdated, res.mutate]);

  return {
    ...res,
    keys,
    updatedAt: res.dataUpdatedAt,
    entity: res.data || defaultEntity,
    cacheOptimization,
    mutateUpdated,
  };
};
