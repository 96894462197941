import { useMemo } from 'react';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { Either } from '@playq/irt';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { AppID, Environment, Filter, TimePrecision } from '@playq/octopus-common';

import {
  useEitherQuery,
  useQueryPrefetch,
  UseQueryOptionsExtended,
  useMutateQueryDataAfterEntityDeleting,
} from '/api/service-hooks';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { getEnabledOrPrefetchStatus } from '/component/Support/BeetlesLookup/helpers';
import { IBaseSort } from '/common/models';
import { useDeleteCacheBatch } from '/component/Support/BeetlesLookup/hook';
import { timeUnitPrecisionMillisecondValues } from '/constants';

import { createMutateUpdated, createMutateUpdatedBatchStatus } from './cache';
/**
 * @template R - a successful response type.
 * @template ID - entity ID type.
 * @template E - entity type.
 * @template S - entity serialized.
 * @template T - sort type.
 */
export const useQueryBase = <
  R extends { total: number },
  E extends { totalNotes: number },
  ID extends { serialize: () => string },
  T extends IBaseSort<string>,
  S extends { id: string },
>(
  keys: QueryKey,
  env: Environment,
  service: (
    appID: AppID,
    iterator: OffsetLimit,
    sortBy: T[],
    filterBy: {
      [key: string]: Filter;
    },
    env: Environment
  ) => Promise<Either<GenericFailure, R | undefined>>,
  query: {
    iterator: OffsetLimit;
    sortBy: T[];
    filterBy: {
      [key: string]: Filter;
    };
  },
  getID: (entity?: E) => ID | undefined,
  getEntities: (response: R) => E[],
  setEntities: (response: R, entities: E[]) => void,
  responseClass: (prevResponse: R) => R,
  getSerialize: (response: R) => S[],
  responseCs: (newArray: S[], updatedIterator: OffsetLimit, updatedTotal: number) => R,
  options?: UseQueryOptionsExtended<R | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID) as AppID;
  const queryClient = useQueryClient();

  const { sortBy, iterator, filterBy } = query;
  const queryKeys: QueryKey = useMemo(
    () => [...keys, appID, iterator, sortBy, filterBy, env],
    [keys, appID, iterator, sortBy, filterBy, env]
  );
  const enabled = useMemo(() => getEnabledOrPrefetchStatus(options?.enabled, appID), [appID, options?.enabled]);
  const enablePrefetch = useMemo(
    () => getEnabledOrPrefetchStatus(options?.enablePrefetch, appID),
    [appID, options?.enablePrefetch]
  );

  const { mutate, refetch, ...res } = useEitherQuery(queryKeys, () => service(appID, iterator, sortBy, filterBy, env), {
    keepPreviousData: true,
    staleTime: timeUnitPrecisionMillisecondValues[TimePrecision.Minute] * 5,
    onError: (err) => {
      const error = err?.message || new Error('An error occurred');
      snackbarService.genericFailure(error);
    },
    enabled,
    ...options,
  });
  const entities = getEntities(res.data || ({} as R));
  const total = res.data?.total ?? 0;

  const { nextKeys } = useQueryPrefetch({
    keys: queryKeys,
    enabled: enablePrefetch,
    total,
    args: [appID, iterator, sortBy, filterBy, env],
    serviceMethod: service,
  });
  const mutateDeletedEntity = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total,
    iterator,
    mutate,
    refetch,
    getID,
    getEntities,
    setEntities,
    createNewResponse: (prevResponse) => responseClass(prevResponse),
  });
  const removeItemBatch = useDeleteCacheBatch({
    mutate,
    total,
    nextKeys,
    getSerialize,
    responseClass: responseCs,
    queryClient,
  });

  const mutateUpdated = createMutateUpdated(mutate);
  const mutateUpdatedBatchStatus = createMutateUpdatedBatchStatus(mutate);

  return {
    ...res,
    queryKeys,
    entities,
    total,
    refetch,
    mutateDeletedEntity,
    mutateUpdated,
    mutateUpdatedBatchStatus,
    removeItemBatch,
  };
};
