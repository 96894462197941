import { AppSupportRequest, AppIssue, MetaResponse } from '@playq/services-beetle';
import { AppID, Environment } from '@playq/octopus-common';
import { Either } from '@playq/irt';
import { GenericFailure, CommonSuccess } from '@playq/services-shared';

import { services2 } from '/api/services2';
import { SupportContentType, TBettleId, TBettleStatus } from '/component/Support/BeetlesLookup/types';

interface IMethods {
  updateBatch: (
    ids: TBettleId[],
    status: TBettleStatus,
    env: Environment
  ) => Promise<Either<GenericFailure, CommonSuccess>>;
  updateStatus: (
    id: TBettleId,
    status: TBettleStatus,
    env: Environment
  ) => Promise<Either<GenericFailure, CommonSuccess | AppSupportRequest>>;
  queryMeta: (
    appID: AppID,
    keys: number,
    valuesPerKey: number,
    env: Environment
  ) => Promise<Either<GenericFailure, MetaResponse>>;
  deleteBatch: (ids: TBettleId[], env: Environment) => Promise<Either<GenericFailure, CommonSuccess>>;
  deleteEntity: (ids: TBettleId, env: Environment) => Promise<Either<GenericFailure, CommonSuccess>>;
  retrieve: (id: TBettleId, env: Environment) => Promise<Either<GenericFailure, AppIssue | AppSupportRequest>>;
  addNote: (id: TBettleId, note: string, env: Environment) => Promise<Either<GenericFailure, string>>;
  deleteNote: (id: TBettleId, noteId: string, env: Environment) => Promise<Either<GenericFailure, CommonSuccess>>;
  updateNote: (
    id: TBettleId,
    noteId: string,
    note: string,
    env: Environment
  ) => Promise<Either<GenericFailure, CommonSuccess>>;
}

export const methods = (method: SupportContentType): IMethods => {
  switch (method) {
    case SupportContentType.ISSUES:
    case SupportContentType.ISSUES_ATTACHMENTS:
      return {
        deleteEntity: services2.audienceSupportService.deleteIssue.bind(services2.audienceSupportService),
        updateBatch: services2.audienceSupportService.updateIssueStatusBatch.bind(services2.audienceSupportService),
        updateStatus: services2.audienceSupportService.updateIssueStatus.bind(services2.audienceSupportService),
        queryMeta: services2.audienceSupportService.queryIssuesMeta.bind(services2.audienceSupportService),
        deleteBatch: services2.audienceSupportService.deleteIssueBatch.bind(services2.audienceSupportService),
        retrieve: services2.audienceSupportService.retrieveIssue.bind(services2.audienceSupportService),
        addNote: services2.audienceSupportService.addIssueNote.bind(services2.audienceSupportService),
        deleteNote: services2.audienceSupportService.deleteIssueNote.bind(services2.audienceSupportService),
        updateNote: services2.audienceSupportService.updateIssueNote.bind(services2.audienceSupportService),
      } as IMethods;

    case SupportContentType.SUPPORT_REQUESTS:
    case SupportContentType.SUPPORT_REQUESTS_ATTACHMENTS:
      return {
        deleteEntity: services2.audienceSupportService.deleteSupportRequest.bind(services2.audienceSupportService),
        updateBatch: services2.audienceSupportService.updateRequestStatusBatch.bind(services2.audienceSupportService),
        updateStatus: services2.audienceSupportService.updateRequestStatus.bind(services2.audienceSupportService),
        queryMeta: services2.audienceSupportService.querySupportRequestsMeta.bind(services2.audienceSupportService),
        deleteBatch: services2.audienceSupportService.deleteSupportRequestBatch.bind(services2.audienceSupportService),
        retrieve: services2.audienceSupportService.retrieveSupportRequest.bind(services2.audienceSupportService),
        addNote: services2.audienceSupportService.addSupportRequestsNote.bind(services2.audienceSupportService),
        deleteNote: services2.audienceSupportService.deleteSupportRequestsNote.bind(services2.audienceSupportService),
        updateNote: services2.audienceSupportService.updateSupportRequestsNote.bind(services2.audienceSupportService),
      } as IMethods;

    default:
      throw new Error('Invalid method');
  }
};
