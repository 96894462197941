import { MutationKey } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { AppSupportRequest } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';

import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { TBettleId, TBettleStatus, SupportContentType } from '/component/Support/BeetlesLookup/types';

import { methods } from './serviceHandlerRequest';
type UpdateStatusProps = { id: TBettleId; status: TBettleStatus; env: Environment };
export const updateStatusMutationKey: MutationKey = ['mutation', 'updateStatus'];

export const useUpdateStatus = (
  method: SupportContentType,
  options?: UseMutationOptionsExtended<CommonSuccess | AppSupportRequest, GenericFailure | Error, UpdateStatusProps>
) => {
  const request = methods(method);
  return useEitherMutation(({ id, status, env }) => request.updateStatus(id, status, env), {
    onError: (err) => snackbarService.genericFailure(err.message),
    mutationKey: updateStatusMutationKey,
    ...options,
  });
};
