import {
  AttachmentSearchQuery,
  SupportRequestsAttachmentSearchResponse,
  AppSupportRequestBrief,
  AppSupportRequestBriefSerialized,
} from '@playq/services-beetle';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { Filter } from '@playq/octopus-common';

import { requestsAttachmentKeys } from '/component/Support/BeetlesLookup/constants';
import { UseQueryOptionsExtended } from '/api/service-hooks';
import { services2 } from '/api/services2';
import { TBettleId } from '/component/Support/BeetlesLookup/types';

import { useAttachmentQueryBase } from './useBaseAttachmentsQuery';

export const useSearchSupportRequestAttachments = (
  query: AttachmentSearchQuery,
  iterator: OffsetLimit,
  filterBy: {
    [key: string]: Filter;
  } = {},
  options?: UseQueryOptionsExtended<SupportRequestsAttachmentSearchResponse | undefined, GenericFailure | Error>
) => {
  return useAttachmentQueryBase<
    SupportRequestsAttachmentSearchResponse,
    AppSupportRequestBrief,
    TBettleId,
    AppSupportRequestBriefSerialized
  >(
    requestsAttachmentKeys,
    { iterator, filterBy },
    services2.audienceSupportService.searchSupportRequestsByAttachments.bind(services2.audienceSupportService),
    query,
    (supportRequestAttachments) => supportRequestAttachments?.id,
    (response) => response.requests ?? [],
    (response, newEntities) => {
      response.requests = newEntities;
    },
    (prevResponse) => new SupportRequestsAttachmentSearchResponse(prevResponse.serialize()),
    (response) => response.serialize().requests,
    (newArray, updatedIterator) =>
      new SupportRequestsAttachmentSearchResponse({
        requests: newArray,
        iterator: updatedIterator,
      }),
    options
  );
};
