import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';

import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { audienceQueryKeys } from '/component/Support/BeetlesLookup/constants';
import { SupportContentType, TBettleId } from '/component/Support/BeetlesLookup/types';

import { methods } from './serviceHandlerRequest';
export const useAddNote = (
  type: SupportContentType,
  options?: UseMutationOptions<string, GenericFailure | Error, { id: TBettleId; note: string; env: Environment }>
) => {
  const service = methods(type);
  return useEitherMutation(({ id, note, env }) => service.addNote(id, note, env), {
    onError: (err) => snackbarService.genericFailure(err),
    removeQueriesKeys: audienceQueryKeys,
    ...options,
  });
};
