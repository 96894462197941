import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { QueryKey, useQueryClient } from '@tanstack/react-query';

import { AttachmentSearchQuery } from '@playq/services-beetle';
import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { Filter, AppID, Environment, TimePrecision } from '@playq/octopus-common';
import { Either } from '@playq/irt';

import { appToolkit } from '/store';
import {
  UseQueryOptionsExtended,
  useEitherQuery,
  useMutateQueryDataAfterEntityDeleting,
  useQueryPrefetch,
} from '/api/service-hooks';
import { countAttachment, getEnabledOrPrefetchStatus } from '/component/Support/BeetlesLookup/helpers';
import { snackbarService } from '/common/snackbarService';
import { IBeetleParams } from '/component/Support/BeetlesLookup/types';
import { useDeleteCacheBatch } from '/component/Support/BeetlesLookup/hook';
import { timeUnitPrecisionMillisecondValues } from '/constants';

import { createMutateUpdated, createMutateUpdatedBatchStatus } from './cache';
/**
 * @template R - a successful response type.
 * @template ID - entity ID type.
 * @template E - entity type.
 * @template S - entity serialized.
 */
export const useAttachmentQueryBase = <
  R extends object,
  E extends { totalNotes: number },
  ID extends { serialize: () => string },
  S extends { id: string },
>(
  keys: QueryKey,
  initialQuery: {
    iterator: OffsetLimit;
    filterBy: {
      [key: string]: Filter;
    };
  },
  serviceMethod: (
    appID: AppID,
    query: AttachmentSearchQuery,
    iterator: OffsetLimit,
    filterBy: {
      [key: string]: Filter;
    },
    env: Environment
  ) => Promise<Either<GenericFailure, R | undefined>>,
  query: AttachmentSearchQuery,
  getID: (entity?: E) => ID | undefined,
  getEntities: (response: R) => E[],
  setEntities: (response: R, entities: E[]) => void,
  responseClass: (prevResponse: R) => R,
  getSerialize: (response: R) => S[],
  responseCs: (newArray: S[], updatedIterator: OffsetLimit, updatedTotal: number) => R,

  options?: UseQueryOptionsExtended<R | undefined, GenericFailure | Error>
) => {
  const appID = useSelector(appToolkit.selectors.appID) as AppID;
  const { env = Environment.Live } = useParams<IBeetleParams>();
  const enabled = useMemo(() => getEnabledOrPrefetchStatus(options?.enabled, appID), [appID, options?.enabled]);
  const enablePrefetch = useMemo(
    () => getEnabledOrPrefetchStatus(options?.enablePrefetch, appID),
    [appID, options?.enablePrefetch]
  );
  const { iterator, filterBy } = initialQuery;

  const queryKeys: QueryKey = useMemo(
    () => [...keys, appID, query, iterator, filterBy, env],
    [keys, appID, query, iterator, filterBy, env]
  );
  const queryClient = useQueryClient();
  const { mutate, refetch, ...res } = useEitherQuery(
    queryKeys,
    () => serviceMethod(appID, query, iterator, filterBy, env),
    {
      keepPreviousData: true,
      staleTime: timeUnitPrecisionMillisecondValues[TimePrecision.Minute] * 5,
      onError: (err) => {
        const error = err?.message || new Error('Could not retrieve issues');
        snackbarService.genericFailure(error);
      },
      enabled,
      ...options,
    }
  );
  const entityAttachments = getEntities(res.data || ({} as R));
  const entityAttachmentsLength = entityAttachments.length ?? 0;

  const { nextKeys, nextIterator, preFetchData, setPreFetchData } = useQueryPrefetch({
    keys: queryKeys,
    enabled: enablePrefetch,
    total: entityAttachmentsLength,
    args: [appID, query, iterator, filterBy, env],
    serviceMethod: serviceMethod,
    hasMore: entityAttachmentsLength === iterator.limit ? true : undefined,
  });
  const prefetchLength = getEntities(preFetchData || ({} as R)).length;
  const total = useMemo(
    () => countAttachment(nextIterator, entityAttachmentsLength, iterator, prefetchLength ?? iterator.limit),
    [entityAttachmentsLength, iterator, nextIterator, prefetchLength]
  );
  useEffect(() => {
    function handlePreFetchData() {
      const isLimitReached = entityAttachmentsLength === nextIterator?.serialize().limit;

      if (isLimitReached) {
        if (nextKeys) {
          setPreFetchData(queryClient.getQueryData(nextKeys));
        }
      }
      return () => setPreFetchData(undefined);
    }
    handlePreFetchData();
    //nextKeys  makes event-loop in deps bacause we have query(calendar date) in cache that update every seconds
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityAttachmentsLength, queryClient, nextIterator, setPreFetchData]);

  const totalAttachment = useMemo(() => (entityAttachmentsLength === 0 ? 0 : total), [entityAttachmentsLength, total]);

  useEffect(() => {
    if (query?.query?.length > 0) {
      queryClient.removeQueries();
    }
    return;
  }, [query.query, preFetchData, iterator.limit, queryClient]);

  const mutateDeletedEntity = useMutateQueryDataAfterEntityDeleting({
    queryClient,
    nextPageQueryKey: nextKeys,
    total: totalAttachment,
    iterator,
    mutate,
    refetch,
    getID,
    getEntities,
    setEntities,
    createNewResponse: (prevResponse) => responseClass(prevResponse),
  });

  const removeItemBatch = useDeleteCacheBatch({
    mutate,
    nextKeys: nextKeys,
    total: totalAttachment,
    getSerialize,
    responseClass: responseCs,
    queryClient,
  });

  const mutateUpdated = createMutateUpdated(mutate);
  const mutateUpdatedBatchStatus = createMutateUpdatedBatchStatus(mutate);

  return {
    ...res,
    queryKeys,
    entities: entityAttachments,
    total,
    refetch,
    mutateDeletedEntity,
    mutateUpdated,
    mutateUpdatedBatchStatus,
    removeItemBatch,
  };
};
