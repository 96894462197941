import { UseMutationOptions } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';

import { useEitherMutation } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { TBettleId, SupportContentType } from '/component/Support/BeetlesLookup/types';

import { methods } from './serviceHandlerRequest';

export const useUpdateNote = (
  type: SupportContentType,
  options?: UseMutationOptions<
    CommonSuccess,
    GenericFailure | Error,
    { id: TBettleId; noteId: string; env: Environment; note: string }
  >
) => {
  const service = methods(type);
  return useEitherMutation(({ id, noteId, note, env }) => service.updateNote(id, noteId, note, env), {
    onError: (err) => snackbarService.genericFailure(err),
    ...options,
  });
};
