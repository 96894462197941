import { UseQueryOptions, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import {
  ClusterizationTaskId,
  ClusterizationRunSort,
  ClusterizationRunSerialized,
  ClusterizationRunsResponse,
  ClusterizationRunId,
} from '@playq/services-beetle';
import { Filter, Environment } from '@playq/octopus-common';

import { useEitherQuery, useQueryPrefetch } from '/api/service-hooks';
import { services2 } from '/api';
import { snackbarService } from '/common/snackbarService';
import { useDeleteCacheBatch } from '/component/Support/BeetlesLookup/hook';

const queryRunsKeys: unknown[] = ['run', 'query'];

export const useQueryRuns = (
  id: ClusterizationTaskId,
  iterator: OffsetLimit,
  sortBy: ClusterizationRunSort[],
  filterBy: {
    [key: string]: Filter;
  },
  env: Environment,
  options?: UseQueryOptions<ClusterizationRunsResponse | undefined, GenericFailure | Error>
) => {
  const { mutate, refetch, ...res } = useEitherQuery(
    queryRunsKeys.concat(id, iterator, sortBy, filterBy, env),
    () => {
      return services2.audienceRequestsClusterizationService.queryRuns(id, iterator, sortBy, filterBy, env);
    },
    {
      keepPreviousData: true,
      onError: (err) => snackbarService.genericFailure(err),
      ...options,
    }
  );

  const runs = useMemo(() => res.data?.requests || [], [res.data]);
  const total = useMemo(() => res.data?.total ?? 0, [res.data?.total]);

  const keys = useMemo(
    () => queryRunsKeys.concat(id, iterator, sortBy, filterBy, env),
    [id, iterator, env, sortBy, filterBy]
  );
  const { nextKeys } = useQueryPrefetch({
    keys,
    enabled: false,
    total,
    args: [id, iterator, sortBy, filterBy, env],
    serviceMethod: services2.audienceRequestsClusterizationService.queryRuns.bind(services2.audienceSupportService),
  });
  const queryClient = useQueryClient();
  const removeSelectedBatch = useDeleteCacheBatch<
    ClusterizationRunsResponse,
    ClusterizationRunId,
    ClusterizationRunSerialized
  >({
    mutate,
    total,
    nextKeys,
    getSerialize: (response) => [...response.serialize().requests],
    responseClass: (newArray, updatedIterator, updatedTotal) =>
      new ClusterizationRunsResponse({
        requests: newArray,
        iterator: updatedIterator,
        total: updatedTotal,
      }),
    queryClient: queryClient,
  });

  return { ...res, queryRuns: { runs, total }, refetch, removeSelectedBatch };
};
