import { MutationKey } from '@tanstack/react-query';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { Environment } from '@playq/octopus-common';

import { useEitherMutation, UseMutationOptionsExtended } from '/api/service-hooks';
import { snackbarService } from '/common/snackbarService';
import { SupportContentType, TBettleId } from '/component/Support/BeetlesLookup/types';

import { methods } from './serviceHandlerRequest';

export type DeleteBatchPayloadType = {
  ids: TBettleId[];
  env: Environment;
};

export const deleteBatchMutationKey: MutationKey = ['mutation', 'delete', 'batch'];

export const useDeleteBatch = (
  method: SupportContentType,
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, DeleteBatchPayloadType>
) => {
  const { deleteBatch } = methods(method);
  return useEitherMutation(({ ids, env }) => deleteBatch(ids, env), {
    onError: (err) => snackbarService.genericFailure(err.message),
    mutationKey: deleteBatchMutationKey.concat(method),
    ...options,
  });
};
