import { Updater } from '@tanstack/react-query';

import { IssuesResponse, SupportRequestsResponse, IssuesAttachmentsSearchResponse } from '@playq/services-beetle';

import {
  TCommonUpdateBatchProps,
  TCommonUpdateProps,
  TIssueParams,
  TIssuesAttachmentBatchParams,
  TIssuesAttachmentParams,
  TIssuesParams,
  TRequestParams,
  TRequestsParams,
  TUpdateBatchData,
  TUpdateData,
  UpdateBatchResponseParams,
  UpdateResponseParams,
} from '/component/Support/BeetlesLookup/types';

/**
 * @template R - a successful response type.
 * @template ID - entity ID type.
 * @template Q - previous serialized response.
 * @template S - status of entity R.
 * @template T - current entity(issue or request) from R .
 */

const issues = 'issues';
const requests = 'requests';

export const createMutateUpdated = <R extends object>(
  mutate: (updater: Updater<R | undefined, R | undefined>) => void
) => {
  return ({ id, status }: TUpdateData) => {
    mutate((prevResponse) => {
      if (!prevResponse) {
        return undefined;
      }

      return updateResponse<R>({ prevResponse, id, status }) as R;
    });
  };
};

export const createMutateUpdatedBatchStatus = <R extends object>(
  mutate: (updater: Updater<R | undefined, R | undefined>) => void
) => {
  return ({ ids, status }: TUpdateBatchData) => {
    mutate((prevResponse) => {
      if (!prevResponse) {
        return;
      }
      return updateBatchResponse({ prevResponse, ids, status }) as R;
    });
  };
};

export const updateResponse = <R extends object>({ prevResponse, id, status }: TCommonUpdateProps<R>) => {
  if (prevResponse instanceof IssuesResponse) {
    return applyUpdate({
      prevResponse,
      id,
      status,
      Constructor: IssuesResponse,
      key: issues,
      updateFn: (issue, stateStatus) => {
        issue.resolved = stateStatus;
        return issue;
      },
    } as TIssueParams);
  } else if (prevResponse instanceof SupportRequestsResponse) {
    return applyUpdate({
      prevResponse,
      id,
      status,
      Constructor: SupportRequestsResponse,
      key: requests,
      updateFn: (request, stateStatus) => {
        request.status = stateStatus;
        return request;
      },
    } as TRequestParams);
  } else if (prevResponse instanceof IssuesAttachmentsSearchResponse) {
    return applyUpdate({
      prevResponse,
      id,
      status,
      Constructor: IssuesAttachmentsSearchResponse,
      key: issues,
      updateFn: (issueAttachment, stateStatus) => {
        issueAttachment.resolved = stateStatus;
        return issueAttachment;
      },
    } as TIssuesAttachmentParams);
  }
};

const applyUpdate = <
  R extends { serialize: () => Q },
  ID extends { serialize: () => string },
  S,
  T extends { id: { serialize: () => string } },
  Q,
>({
  prevResponse,
  Constructor,
  id,
  status,
  key,
  updateFn,
}: UpdateResponseParams<R, ID, S, Q, T>) => {
  const updateItems = (prevResponse[key] as unknown as T[]).map((item) => {
    if (item.id.serialize() !== id.serialize()) {
      return item;
    }
    return updateFn(item, status);
  });

  const newResponse = new Constructor(prevResponse.serialize());
  newResponse[key] = updateItems as R[keyof R];
  return newResponse;
};

export const updateBatchResponse = <R>({ prevResponse, ids, status }: TCommonUpdateBatchProps<R>) => {
  if (prevResponse instanceof IssuesResponse) {
    return applyBatchUpdate({
      prevResponse,
      ids,
      status,
      Constructor: IssuesResponse,
      key: issues,
      updateFn: (issue, stateStatus) => {
        issue.resolved = stateStatus;
        return issue;
      },
    } as TIssuesParams);
  } else if (prevResponse instanceof SupportRequestsResponse) {
    return applyBatchUpdate({
      prevResponse,
      ids,
      status,
      Constructor: SupportRequestsResponse,
      key: requests,
      updateFn: (request, stateStatus) => {
        request.status = stateStatus;
        return request;
      },
    } as TRequestsParams);
  } else if (prevResponse instanceof IssuesAttachmentsSearchResponse) {
    return applyBatchUpdate({
      prevResponse,
      ids,
      status,
      Constructor: IssuesAttachmentsSearchResponse,
      key: issues,
      updateFn: (issueAttachment, stateStatus) => {
        issueAttachment.resolved = stateStatus;
        return issueAttachment;
      },
    } as TIssuesAttachmentBatchParams);
  }
  throw new Error('Invalid response type');
};

const applyBatchUpdate = <
  R extends { serialize: () => Q },
  ID extends { serialize: () => string },
  S,
  T extends { id: { serialize: () => string } },
  Q,
>({
  prevResponse,
  ids,
  status,
  Constructor,
  key,
  updateFn,
}: UpdateBatchResponseParams<R, ID, S, Q, T>) => {
  const newResponse = new Constructor(prevResponse.serialize());
  const updateItems = newResponse[key] as unknown as T[];

  const selectedIndexes = ids.map((id) => {
    return updateItems.findIndex((item) => item.id.serialize() === id.serialize());
  });

  newResponse[key] = updateItems.map((value, i) => {
    if (selectedIndexes.includes(i)) {
      return updateFn(value, status);
    }
    return value;
  }) as R[keyof R];

  return newResponse;
};
