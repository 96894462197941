import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { MutationKey } from '@tanstack/query-core';

import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import { AppID } from '@playq/octopus-common';

import { UseMutationOptionsExtended, useEitherMutation } from '/api/service-hooks';
import { services2 } from '/api/services2';
import { snackbarService } from '/common/snackbarService';
import { appToolkit } from '/store';
import { IBeetleParams } from '/component/Support/BeetlesLookup/types';

const dropIndexKey: MutationKey = ['attachments', 'index', 'drop'];
export const useDroIndexpAttachmentsFor = (
  options?: UseMutationOptionsExtended<CommonSuccess, GenericFailure | Error, { from_: Date; to: Date }>
) => {
  const app = useSelector(appToolkit.selectors.app);
  const appId = app?.id as AppID;
  const { env } = useParams<IBeetleParams>();
  return useEitherMutation(
    ({ from_: from, to }) => services2.audienceSupportService.dropAttachmentsIndexFor(appId, from, to, env),
    {
      onError: (err) => snackbarService.genericFailure(err.message),
      mutationKey: dropIndexKey,
      ...options,
    }
  );
};
