import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import { IssuesResponse, IssueSort, AppIssueBrief, AppIssueBriefSerialized } from '@playq/services-beetle';
import { Filter, Environment } from '@playq/octopus-common';

import { services2 } from '/api/services2';
import { UseQueryOptionsExtended } from '/api/service-hooks';
import { issueKeys } from '/component/Support/BeetlesLookup/constants';
import { QueryHelpers } from '/helpers/query';
import { TBettleId, TIssueOrRequestSort } from '/component/Support/BeetlesLookup/types';

import { useQueryBase } from './useCustomQuery';

export const useQueryIssues = (
  env: Environment = Environment.Live,
  iterator: OffsetLimit = QueryHelpers.getInitIterator(),
  sortBy: TIssueOrRequestSort[] = [],
  filterBy: {
    [key: string]: Filter;
  } = {},
  options?: UseQueryOptionsExtended<IssuesResponse | undefined, GenericFailure | Error>
) => {
  return useQueryBase<IssuesResponse, AppIssueBrief, TBettleId, IssueSort, AppIssueBriefSerialized>(
    issueKeys,
    env,
    services2.audienceSupportService.queryIssues.bind(services2.audienceSupportService),
    { iterator, sortBy: sortBy as IssueSort[], filterBy },
    (issue) => issue?.id,
    (response) => response.issues ?? [],
    (response, newEntities) => (response.issues = newEntities),
    (prevResponse) => new IssuesResponse(prevResponse.serialize()),
    (res) => res.serialize().issues,
    (newArray, updatedIterator, updatedTotal) =>
      new IssuesResponse({
        issues: newArray,
        iterator: updatedIterator,
        total: updatedTotal,
      }),
    options
  );
};
